export default {
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.qeeper.co.uk/',
    site_name: 'Qeeper',
  },
  // twitter: {
  //   handle: '@handle',
  //   site: '@site',
  //   cardType: 'summary_large_image',
  // },
};
